/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export default function convertAlphaDecToHex(colorHex, alphaDecimal) {
    /**
     * @param string color hex value to add apha to
     * @param Number alphaDecimal should be a value between 0 and 1)
     * @return String hex value with 2 'digit' alpha at end "00" to "FF"
     */
    let opacityHexString = 'FF';
    if (alphaDecimal !== 1) {
        opacityHexString = Math.trunc(alphaDecimal * 100 * 2.55).toString(16);
    }
    return `${colorHex}${opacityHexString}`;
}
