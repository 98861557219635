/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    array, object, func, string,
} from 'prop-types';
import {
    FormControl, Input, InputLabel, NativeSelect,
} from '@material-ui/core';

const OccasionsDropDownField = ({
    data, classes, setSelectedOccasion, occasion, stylingObj, arrowDropDownIcon, occasionLabel,
}) => (
    <FormControl fullWidth>
        <InputLabel
            shrink
            htmlFor="Occasion-native-label-placeholder"
            className={classes.ctInputLabel}
            style={{ color: stylingObj?.fieldLabels }}
        >
            {occasionLabel}
        </InputLabel>
        <NativeSelect
            disableUnderline
            value={occasion?.name}
            onChange={(e) => {
                setSelectedOccasion(e.target.value);
            }}
            className={classes.ctSelect}
            style={{ border: stylingObj?.fieldBorders }}
            IconComponent={() => (arrowDropDownIcon)}
            input={(
                <Input
                    name="occasion"
                    id="occasion-native-label-placeholder"
                    data-test="hp-occasion-native-label-placeholder"
                />
            )}
        >
            {data.occasions?.map((d) => (
                <option
                    value={d.occasion_title}
                    key={d.occasion_title}
                >
                    {d.occasion_title}
                </option>
            ))}
        </NativeSelect>
    </FormControl>
);

OccasionsDropDownField.propTypes = {
    data: array,
    classes: object.isRequired,
    setSelectedOccasion: func,
    arrowDropDownIcon: string,
    stylingObj: object,
    occasion: object.isRequired,
    occasionLabel: string.isRequired,
};
OccasionsDropDownField.defaultProps = {
    data: [],
    stylingObj: {},
    setSelectedOccasion: () => {},
    arrowDropDownIcon: '',
};

export default OccasionsDropDownField;
