/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import {
    bool,
    func,
    object,
    shape,
    string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    FormControl, Input, InputLabel, NativeSelect,
} from '@material-ui/core';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ZipInputField from '../Partials/ZipInputField';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { setProductFilterOccasion, setUserSubmittedProductFilterZipcode } from '../../../../../../../state/ducks/App/App-Actions';
import { emitCustomTrackingEvent } from '../../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { getValidatedZipcode, getProductFilterProfile } from '../../../../../../../state/ducks/App/App-Selectors';
import GiftFinderLogOutProfile from './GiftFinderLogOutProfile';
import { parseFont } from '../../../../../../helpers/common/helper';
import GiftFinderLoginContainer from './GiftFinderLoginContainer';
import { getIsAuthenticatedStatus } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import GiftFinderProductContainer from '../../../../GiftFinderProduct/GiftFinderProductContainer';

const useStyles = makeStyles((theme) => ({
    ctGfForm: {
        margin: 0,
        '@media screen and (max-width: 767px)': {
            margin: '0px 15px 15px',
        },
    },
    ctGfContainer: {
        width: '100%',
        borderRadius: '5px',
        padding: '28px',
    },
    ctGfInnerContainer: {
        maxWidth: '978px',
        margin: '0 auto',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media screen and (max-width: 767px)': {
            padding: '0',
            height: 'auto',
            flexWrap: 'wrap',
        },
    },
    iconAndHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '40px',
        textAlign: 'center',
        '@media screen and (max-width: 600px)': {
            width: '100%',
            alignItems: 'stretch',
            justifyContent: 'center',
            marginRight: '0px',
            marginBottom: '20px',
        },
    },
    ctHeader: ({ data }) => ({
        width: '100%',
        fontWeight: 'normal',
        fontSize: '34px',
        lineHeight: '30px',
        color: data?.styling?.font_color?.color || '#2f2f2f',
        fontFamily: parseFont(data?.styling?.font_family) || 'Playfair Display',
        letterSpacing: '0px',
        margin: '0px',
        '@media screen and (max-width: 767px)': {
            fontSize: '28px',
        },
        '@media screen and (min-width: 767px)': {
            '&:before': {
                right: 15,
                width: '28%',
                height: 1,
                content: '""',
                display: 'inline-block',
                position: 'relative',
                verticalAlign: 'middle',
                backgroundColor: data?.styling?.font_color?.color || '#2f2f2f',
            },
            '&:after': {
                left: 15,
                width: '28%',
                height: 1,
                content: '""',
                display: 'inline-block',
                position: 'relative',
                verticalAlign: 'middle',
                backgroundColor: data?.styling?.font_color?.color || '#2f2f2f',
            },
        },
    }),
    ctFieldContainer: {
        flex: '1 1 auto',
    },
    ctFieldInnerContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        maxWidth: '700px',
        margin: '0 auto',
        '@media screen and (max-width: 600px)': {
            flexWrap: 'wrap',
        },
    },
    ctInputContainer: {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
        '&:not(:last-child)': {
            marginRight: '18px',
        },
        '@media screen and (max-width: 600px)': {
            paddingBottom: '20px',
            marginRight: '0px',
        },
    },
    errorMessage: {
        '&:after': {
            bottom: -75,
            display: 'block',
            textAlign: 'left',
        },
    },
    successMessage: {
        '&:after': {
            bottom: 0,
            display: 'block',
        },
    },
    ctSelect: {
        fontSize: '18px',
        marginTop: '20px !important',
        background: theme.palette.cms?.white || '#ffffff',
        border: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        position: 'relative',
        '& input, & select': {
            height: '42px',
            padding: '0 12px 2px 12px',
            fontSize: '16px',
            color: '#000',
            borderRadius: '4px',
            boxShadow: '0px 0px 0px 1px #E1C3E5',
            outline: 0,
            '&:focus, &:active': {
                borderRadius: '4px',
                boxShadow: '0px 0px 0px 1px #E1C3E5',
                outline: 0,
                background: theme.palette.cms?.white || '#ffffff',
            },
        },
        '& svg': {
            position: 'absolute',
            right: '10px',
        },
        '&.Mui-focused': {
            background: 'transparent',
        },
    },
    ctInputLabel: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: '#595959',
        fontSize: '14px',
        lineHeight: '16px',
        '@media screen and (max-width: 1025px)': {
            fontSize: '16px',
        },
    },

    ctButtonContainer: {
        flexGrow: '0',
        maxWidth: '100%',
        flexBasis: '100%',
        marginTop: '12px',
    },
    noDisplayDateFieldMarginBottom: {
        marginBottom: '6px',
    },
    ctButton: {
        fontFamily: 'LatoMedium',
        fontSize: '18px',
        lineHeight: '18px',
        letterSpacing: '0.04em',
        color: theme.palette.cms?.white || '#ffffff',
        fontWeight: 700,
        height: '45px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: 3,
        padding: 0,
        cursor: 'pointer',
        boxShadow: 'inherit',
        backgroundColor: theme?.palette?.colorPrimary || '#65388b',
    },
    name: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        width: '97%',
        whiteSpace: 'nowrap',
    },
    giftFinderHorizontal: {
        margin: '0 auto',
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: '24px',
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px 30px',
            margin: '0px 12px',
        },
    },
}));

const FloralProfileGiftFinder =  (
    {
        data, userSubmittedZip, zip, handleChange, zipcodeIsValid, occasion, ldGiftFinderVariant,
    },
) => {
    const occasion_option_group = data?.floral_finder_criteria?.occasions || [];
    const classes = useStyles({ data });
    const [relationship, setRelationship] = useState({});
    const sectionTitle = data?.heading || '';
    const giftFinderBgColor = data?.styling?.background_color?.color || '';
    const productData = data?.product_recommendations || [];
    const dispatch = useDispatch();
    const [onSubmitCheck, setSubmitCheck] = useState(false);
    const validatedZip = useSelector(getValidatedZipcode);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const selectedProfileInfo = useSelector(getProductFilterProfile) || { name: '', relationship: '' };
    const ffIsGiftFinderTestEnabled = useSelector(getFeatureFlag('is-gift-finder-test-enabled'));
    const isGiftSuggestionEnable = useSelector(getFeatureFlag('is-gift-finder-suggestions-enabled'));
    const { whichGiftFinderSuggestion } = useFlags();
    const ffIsGFSuggestionsEnabled = (isGiftSuggestionEnable && whichGiftFinderSuggestion === 'variant' && data?.has_recommendations);
    const history = useHistory();
    let ldClient;
    if (ffIsGiftFinderTestEnabled && typeof window !== 'undefined') {
        ldClient = useLDClient();
    }

    useEffect(() => {
        const defaultOccasion = occasion_option_group?.[0]?.occasion_title || 'Birthday';
        const defaultCodeOccasion = occasion_option_group?.[0]?.occasion_code || '3';
        dispatch(setProductFilterOccasion(defaultOccasion, defaultCodeOccasion));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const occasionFound = occasion_option_group?.find(
            (d) => d.occasion_title === occasion?.name,
        );
        const occasionValue = occasionFound?.occasion_url;
        const { name, relationshipName: selectedRelationShip = '' } = selectedProfileInfo;
        if (zip.length < 5 && onSubmitCheck === false) {
            setSubmitCheck(true);
        } else if (
            zip.length >= 5
            && validatedZip !== ''
            && validatedZip === zip
        ) {
            const trackData = {
                ea: data?.a_b_testing?.submission_tacking_event_action || data?.tracking_event_action || 'Homepage',
                ec: data?.a_b_testing?.submission_tacking_event_category || data?.tracking_event_category || 'Gift Finder Gateway',
                el: data?.a_b_testing?.submission_tacking_event_label ? data?.a_b_testing?.submission_tacking_event_label.replace('{{Zip}}', zip).replace('{{Occasion}}', occasionFound?.occasion_title).replace('{{Profile}}', selectedRelationShip) : '',
            };
            if (ffIsGiftFinderTestEnabled) {
                ldClient.track('Gift Finder Button Clicked');
            }
            dispatch(trackEvent({
                eventCategory: trackData.ec,
                eventAction: trackData.ea,
                eventLabel: trackData?.el || `${zip}|${occasion.name}`,
                giftOccasion: occasion?.name,
                giftZipcode: zip,
            }));
            dispatch(setUserSubmittedProductFilterZipcode(zip.trimEnd()));
            const customTrackingSchema = {
                action: 'customer-input', relationship: selectedRelationShip, occasionCode: occasionFound?.occasion_title, page: { type: 'home' }, zipCode: zip, eventSubType: 'customer-input-find-gift-fast',
            };
            if (isAuthenticated && name) {
                const nameCollection = name.split(' ');
                customTrackingSchema.firstName = nameCollection?.[0];
                customTrackingSchema.lastName = nameCollection?.[1];
            }
            dispatch(emitCustomTrackingEvent(customTrackingSchema));
            if (occasionFound) {
                let url = occasionValue || '';
                let searchParam = '';
                if (occasionValue?.indexOf?.('?') >= 0) {
                    const [pathUrl, pathParam] = occasionValue?.split?.('?') || [];
                    url = pathUrl;
                    searchParam = pathParam;
                }
                const historyData = {
                    pathname: url || occasionValue,
                    state: {
                        giftOccasion: occasion?.name,
                        giftZipcode: zip,
                    },
                };
                if (searchParam) {
                    historyData.search = searchParam;
                }
                history.push(historyData);
            }
        }
    };

    const setSelectedOccasion = (ocassionName) => {
        const occasionOptionGroup = data.floral_finder_criteria?.occasions || [];
        const currentOccasion = occasionOptionGroup.filter((occ) => occ.occasion_title === ocassionName);
        dispatch(setProductFilterOccasion(ocassionName, currentOccasion?.[0]?.occasion_code));
    };
    const ArrowDropDownIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
            <path d="M7.74742 8.65916C7.34962 9.10668 6.65039 9.10668 6.2526 8.65916L0.479437 2.16436C-0.0938094 1.51946 0.363996 0.499999 1.22685 0.499999L12.7732 0.5C13.636 0.5 14.0938 1.51946 13.5206 2.16436L7.74742 8.65916Z" fill="#262626" />
        </svg>
    );
    return (
        <>
            <form className={classes.ctGfForm} onSubmit={handleSubmit}>
                <div className={classes.ctGfContainer} style={{ backgroundColor: giftFinderBgColor }}>
                    <div className={classes.ctGfInnerContainer}>
                        <div className={classes.iconAndHeaderContainer}>
                            <h3 className={classes.ctHeader}>
                                {ReactHtmlParser(sectionTitle?.replace?.('\\n', '<br/>'))}
                            </h3>
                        </div>
                        {isAuthenticated ? <GiftFinderLoginContainer reference={data?.floral_finder_criteria?.profiles} relationship={relationship} setRelationship={setRelationship}  iconStyling={data?.styling} ldGiftFinderVariant={ldGiftFinderVariant} abTesting={data?.a_b_testing} />
                            : <GiftFinderLogOutProfile reference={data?.floral_finder_criteria?.profiles} relationship={relationship} setRelationship={setRelationship}  iconStyling={data?.styling}  ldGiftFinderVariant={ldGiftFinderVariant} abTesting={data?.a_b_testing} /> }
                        <div className={classes.ctFieldContainer}>
                            <div className={classes.ctFieldInnerContainer}>
                                <div className={classes.ctInputContainer}>
                                    <ZipInputField
                                        zipLabel="Recipient ZIP code"
                                        currentInputLength={zip.length}
                                        handleChange={handleChange}
                                        onSubmitCheck={onSubmitCheck}
                                        zipcodeIsValid={zipcodeIsValid}
                                        userSubmittedZip={userSubmittedZip}
                                        value={zip}
                                        giftFinderCodeTheoryView
                                        giftFinderFieldStyle={classes}
                                        inputBorderColor="transparent"
                                    />
                                </div>
                                <div className={classes.ctInputContainer}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            shrink
                                            htmlFor="occasion-native-label-placeholder"
                                            className={classes.ctInputLabel}
                                        >
                                            Occasion
                                        </InputLabel>
                                        <NativeSelect
                                            disableUnderline
                                            value={occasion?.name}
                                            onChange={(e) => {
                                                setSelectedOccasion(e.target.value);
                                            }}
                                            className={classes.ctSelect}
                                            IconComponent={() => (ArrowDropDownIcon)}
                                            input={(
                                                <Input
                                                    name="occasion"
                                                    id="occasion-native-label-placeholder"
                                                    data-test="hp-occasion-native-label-placeholder"
                                                />
                                            )}
                                        >
                                            {occasion_option_group?.map((d) => (
                                                <option
                                                    value={d.occasion_title}
                                                    key={d.occasion_title}
                                                >
                                                    {d.occasion_title}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className={classes.ctButtonContainer}>
                                    <button
                                        id="fagf-btn"
                                        data-testid="fagf-btn"
                                        className={classes.ctButton}
                                        type="submit"
                                    >
                                        Search
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {ffIsGFSuggestionsEnabled && (
                <div
                    className={classes.giftFinderHorizontal}
                    style={{
                        backgroundColor: productData?.styling?.module_background_color?.color,
                    }}
                >
                    <GiftFinderProductContainer productData={productData} />
                </div>
            )}
        </>
    );
};

FloralProfileGiftFinder.propTypes = {
    data: object.isRequired,
    userSubmittedZip: string.isRequired,
    handleChange: func.isRequired,
    zipcodeIsValid: bool,
    occasion: shape({
        code: string,
        name: string,
    }).isRequired,
    zip: string.isRequired,
    ldGiftFinderVariant: string,
};
FloralProfileGiftFinder.defaultProps = {
    zipcodeIsValid: null,
    ldGiftFinderVariant: '',
};

export default FloralProfileGiftFinder;
