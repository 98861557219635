/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useQuery } from '@apollo/client';
import {  useSelector } from 'react-redux';
import { object, func, string } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { GRAPHQL_ENV } from '../../../../../../gql';
import findRecipientRank from '../../../../../../gql/queries/findRecipientRank';
import { getBrand } from '../../../../../../pages/Account/state/ducks/Common/Common-Selectors';
import useGetAccessTokenSafely from '../../../../../../gql/hooks/useGetAccessTokenSafely';
import {  getProfileInfo } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import GiftFinderLogOutProfile from './GiftFinderLogOutProfile';
import GiftFinderLoginProfile from './GiftFinderLoginProfile';

const GiftFinderLoginContainer = ({
    reference, relationship, setRelationship, iconStyling, abTesting, ldGiftFinderVariant,
}) => {
    const jwt = useGetAccessTokenSafely();
    const profile = useSelector(getProfileInfo);
    const brand = useSelector(getBrand);
    const queryVariables = {
        brand: brand.domain || 'harryanddavid',
        environment: GRAPHQL_ENV,
        emailId: profile.email,
        model: 'SCORE',
    };
    const {
        loading, error, data,
    } = useQuery(findRecipientRank(), {
        variables: queryVariables,
        context: {
            api: 'personalizedContent',
            jwt,
        },
        skip: !jwt,

    });
    if (loading) return <></>;
    if (error || !data || data?.recipientRank?.recipientList?.length <= 0) {
        mbpLogger.logError({
            function: 'FindRecipientRank',
            appName: process.env.npm_package_name,
            jsError: error,
            component: 'GiftFinderLoginContainer.js',
            message: 'Failed to fetch data for recipient',
        });
        return (
            <GiftFinderLogOutProfile reference={reference} relationship={relationship} setRelationship={setRelationship} iconStyling={iconStyling} abTesting={abTesting} ldGiftFinderVariant={ldGiftFinderVariant} />
        );
    }
    return  (
        <GiftFinderLoginProfile loginContent={data?.recipientRank} reference={reference} relationship={relationship} setRelationship={setRelationship} iconStyling={iconStyling} abTesting={abTesting} ldGiftFinderVariant={ldGiftFinderVariant} />
    );
};
GiftFinderLoginContainer.propTypes = {
    reference: object.isRequired,
    relationship: object,
    setRelationship: func,
    iconStyling: object,
    abTesting: object,
    ldGiftFinderVariant: string,
};
GiftFinderLoginContainer.defaultProps = {
    relationship: {},
    setRelationship: () => {},
    iconStyling: {},
    abTesting: {},
    ldGiftFinderVariant: '',
};
export default GiftFinderLoginContainer;
