/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// eslint-disable-next-line import/prefer-default-export
export const handleOnClick = (e, path, history, openInSameTab = false) => {
    const isExternalLink = (path.includes('https://') || path.includes('http://')) && typeof window !== 'undefined';
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
    if (isExternalLink) {
        window.open(path, openInSameTab ? '_self' : null);
    } else {
        history.push(path);
        e.persist();
    }
};
