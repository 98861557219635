/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    bool,
    func,
    object,
    shape,
    string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import FloralGFUniversal from './FloralGFUniversal';
import { getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const GiftFinderUniversalContainer =  ({
    blockData, userSubmittedZip, zip, handleChange, onSubmitCheck, zipcodeIsValid, occasion, isPlaEnabled,
}) => {
    const presentationFamily = useSelector(getPresentationFamily);
    return (
        blockData?.presentation_type === 'flowers' && presentationFamily === 'flower'
            ? (
                <FloralGFUniversal
                    data={blockData}
                    userSubmittedZip={userSubmittedZip}
                    zip={zip}
                    handleChange={handleChange}
                    onSubmitCheck={onSubmitCheck}
                    zipcodeIsValid={zipcodeIsValid}
                    occasion={occasion}
                    isPlaEnabled={isPlaEnabled}
                />
            ) : <></>
    );
};

GiftFinderUniversalContainer.propTypes = {
    blockData: object.isRequired,
    userSubmittedZip: string.isRequired,
    handleChange: func.isRequired,
    onSubmitCheck: bool.isRequired,
    zipcodeIsValid: bool,
    occasion: shape({
        code: string,
        name: string,
    }).isRequired,
    zip: string.isRequired,
    isPlaEnabled: bool,
};
GiftFinderUniversalContainer.defaultProps = {
    zipcodeIsValid: null,
    isPlaEnabled: false,
};

export default GiftFinderUniversalContainer;
