/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import {
    bool,
    func,
    object,
    shape,
    string,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    useMediaQuery,
} from '@material-ui/core';

import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ZipInputField from '../Partials/ZipInputField';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { setProductFilterOccasion, setUserSubmittedProductFilterZipcode } from '../../../../../../../state/ducks/App/App-Actions';
import { emitCustomTrackingEvent } from '../../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { getValidatedZipcode, getProductFilterProfile } from '../../../../../../../state/ducks/App/App-Selectors';
import LogOutAddressBook from './LogOutAddressBook';
import { parseFont } from '../../../../../../helpers/common/helper';
import { getIsAuthenticatedStatus } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import OccasionsDropDownField from './OccasionsDropDownField';
import LogInAddressBook from './LogInAddressBook';
import useAddressBookData from '../../../../../../gql/hooks/useAddressBookData';
import GiftFinderProductContainer from '../../../../GiftFinderProduct/GiftFinderProductContainer';

const styles = (theme) => ({
    container: {
        maxWidth: '1180px',
        margin: '0 auto',
    },
    ctGfForm: {
        margin: 0,
        '@media screen and (max-width: 767px)': {
            margin: '0px',
        },
    },
    ctGfContainer: {
        width: '100%',
        borderRadius: '5px',
        padding: '0px',

    },
    gfInnerContainer: {
        margin: '24px 50px',
        padding: '36px 0px',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px',
        '@media screen and (max-width: 1180px) and (min-width:768px)': {
            margin: '24px',
        },
        '@media screen and (max-width: 767px)': {
            margin: '45px 14px',
            padding: '30px 0px 25px',
            height: 'auto',
            flexWrap: 'wrap',
            borderRadius: '5px',
        },
    },
    iconAndHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '24px',
        textAlign: 'center',
        '@media screen and (max-width: 767px)': {
            width: '100%',
            alignItems: 'stretch',
            justifyContent: 'center',
            marginRight: '0px',
        },
    },
    ctHeader: {
        width: '100%',
        fontWeight: '700',
        fontSize: '34px',
        lineHeight: '30px',
        color: theme.palette.cms?.black || '#000000',
        letterSpacing: '0px',
        margin: '0px',
        '@media screen and (max-width: 767px)': {
            fontSize: '28px',
        },
    },
    ctFieldContainer: {
        flex: '1 1 auto',
    },
    ctFieldInnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: '16px',
        margin: '0 auto',
        '@media screen and (max-width: 767px)': {
            flexWrap: 'wrap',
            gap: '0px',
        },
    },
    ctInputContainer: {
        flexGrow: 0,
        maxWidth: '220px',
        flexBasis: '220px',
        '@media screen and (max-width: 1180px) and (min-width:768px)': {
            maxWidth: '22%',
            flexBasis: '22%',
        },
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px 13.5px',
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    errorMessage: {
        '&:after': {
            bottom: -80,
            display: 'block',
            textAlign: 'left',
        },
    },
    successMessage: {
        '&:after': {
            bottom: 0,
            display: 'block',
        },
    },
    ctSelect: {
        fontSize: '18px',
        marginTop: '20px !important',
        background: '#F5F5F5',
        border: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        position: 'relative',
        '& input, & select': {
            height: '42px',
            padding: '0 16px 2px 16px',
            fontSize: '16px',
            color: '#000',
            borderRadius: '4px',
            outline: 0,
            textTransform: 'capitalize',
            '&:focus, &:active': {
                borderRadius: '4px',
                outline: 0,
                background: '#F5F5F5',
            },
            '@media screen and (max-width: 767px)': {
                height: '46px',
            },
        },
        '& svg': {
            position: 'absolute',
            right: '10px',
        },
        '&.Mui-focused': {
            background: 'transparent',
        },
    },
    ctInputLabel: {
        fontStyle: 'normal',
        fontWeight: '400',
        color: '#595959',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        '@media screen and (max-width: 1025px)': {
            fontSize: '16px',
        },
    },

    ctButtonContainer: {
        flexGrow: '0',
        maxWidth: '220px',
        flexBasis: '220px',
        marginTop: '20px',
        position: 'relative',
        '@media screen and (max-width: 1180px) and (min-width:768px)': {
            maxWidth: '22%',
            flexBasis: '22%',
        },
        '@media screen and (max-width: 767px)': {
            marginTop: '16.5px',
            maxWidth: '100%',
            flexBasis: '100%',
            padding: '0px 24px',
        },
    },
    noDisplayDateFieldMarginBottom: {
        marginBottom: '6px',
    },
    ctButton: {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.64px',
        color: theme.palette.cms?.white || '#ffffff',
        fontWeight: 700,
        height: '44px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: 3,
        padding: 0,
        cursor: 'pointer',
        boxShadow: 'inherit',
        backgroundColor: theme?.palette?.colorPrimary || '#65388b',
    },
    name: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        width: '97%',
        whiteSpace: 'nowrap',
    },
    giftFinderBgImage: {
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        '@media screen and (max-width: 767px)': {
            width: '100% !important',
            height: 'auto',
        },
    },
    helperText: {
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        fontSize: '12px',
        overflowY: 'hidden',
        maxHeight: '20px',
        top: '97%',
        color: theme.palette.colorAlert,
        transitionProperty: 'all',
        transitionDuration: '.5s',
        transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
        '@media screen and (max-width: 601px)': {
            top: '100%',
            fontSize: '12px',
            margin: 0,
        },

    },
    error: {
        '& $ctSelect': {
            border: `1px solid ${theme.palette.colorAlert}!important`,
        },
    },
    addressBookContainer: {
        flexGrow: '0',
        maxWidth: '255px',
        flexBasis: '255px',
        '@media screen and (max-width: 1180px) and (min-width:768px)': {
            maxWidth: '22%',
            flexBasis: '22%',
        },
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px 13.5px',
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    giftFinderHorizontal: {
        margin: '0 auto',
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: '24px',
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px 30px',
            margin: '0px 12px',
        },
    },
    plaGiftFinderContainer: {
        '& $giftFinderBgImage': {
            borderRadius: '0px',
        },
        '@media screen and (min-width: 1180px)': {
            '& $ctButtonContainer': {
                maxWidth: '272px',
                flexBasis: '272px',
                marginTop: '18px',
            },
            '& $ctInputContainer': {
                maxWidth: '272px',
                flexBasis: '272px',
            },
            '& $gfInnerContainer': {
                margin: '24px 67px',
            },
            '& $ctSelect': {
                marginTop: '18px !important',
                borderRadius: '8px',
                '& input, & select': {
                    height: '40px',
                },
            },
            '& $ctFieldInnerContainer': {
                gap: '20px',
            },
        },
        '@media screen and (max-width: 1180px) and (min-width:768px)': {
            '& $ctButtonContainer': {
                maxWidth: '28%',
                flexBasis: '28%',
            },
            '& $ctInputContainer': {
                maxWidth: '28%',
                flexBasis: '28%',
            },
        },
        '@media screen and (max-width: 959px)': {
            padding: '24px 0px',
        },
        '@media screen and (max-width: 768px)': {
            '& $gfInnerContainer': {
                margin: '32px 14px',
            },
        },
    },
});

const FloralGFUniversal =  (
    {
        classes, data, userSubmittedZip, zip, handleChange, zipcodeIsValid, occasion, isPlaEnabled,
    },
) => {
    const fieldFloral =  data?.fields_floral || [];
    const isValidation = data?.fields_are_required || false;
    const occasion_option_group = fieldFloral.find((item) => item.occasions)?.occasions?.occasions || [];
    const productData = data?.product_recommendations || [];
    const [relationship, setRelationship] = useState('');
    const sectionTitle = data?.heading || '';
    const colorObj = data?.styling?.colors || {};
    const giftFinderBgColor = data?.styling?.background?.color?.color || '';
    const giftFinderBgImage = () => {
        let bgImageStyle = {};
        if (useMediaQuery('(min-width: 1181px)')) {
            bgImageStyle = {
                backgroundImage: `url(${data.styling?.background?.desktop_image?.url})` || '',
                width: data.styling?.background?.desktop_image?.dimension?.width || '',
                height: data.styling?.background?.desktop_image?.dimension?.height || '',

            };
        }
        if (useMediaQuery('(max-width: 1180px) and (min-width:768px)')) {
            bgImageStyle = {
                backgroundImage: `url(${data.styling.background?.tablet_image?.url ||  data?.styling?.background?.desktop_image?.url })`,
                width: '100%',
                height: data.styling?.background?.tablet_image?.dimension?.height || data?.styling?.background?.desktop_image?.dimension?.height,
            };
        }
        if (useMediaQuery('(max-width: 767px)')) {
            bgImageStyle = {
                backgroundImage: `url(${data?.styling?.background?.mobile_image?.url})` || '',
                height: data.styling?.background?.mobile_image?.dimension?.height || '',
            };
        }
        return bgImageStyle;
    };

    const dispatch = useDispatch();
    const [onSubmitCheck, setSubmitCheck] = useState(false);
    const [errorRelationship, setErrorRelationship] = useState(false);
    const validatedZip = useSelector(getValidatedZipcode);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const selectedProfileInfo = useSelector(getProductFilterProfile) || { name: '', relationship: '' };
    const ffIsGiftFinderTestEnabled = useSelector(getFeatureFlag('is-gift-finder-test-enabled'));
    const isGiftSuggestionEnable = useSelector(getFeatureFlag('is-gift-finder-suggestions-enabled'));
    const { whichGiftFinderSuggestion } = useFlags();
    const ffIsGFSuggestionsEnabled = (isGiftSuggestionEnable && whichGiftFinderSuggestion === 'variant' && data?.has_recommendations);
    const history = useHistory();
    const { data: addressData } = isAuthenticated ? useAddressBookData() : {};
    let ldClient;
    if (ffIsGiftFinderTestEnabled && typeof window !== 'undefined') {
        ldClient = useLDClient();
    }

    useEffect(() => {
        const defaultOccasion = occasion_option_group?.[0]?.occasion_title || 'Birthday';
        const defaultCodeOccasion = occasion_option_group?.[0]?.occasion_code || '3';
        dispatch(setProductFilterOccasion(defaultOccasion, defaultCodeOccasion));
    }, []);

    // Check if at least one object in the array has the "Profile" key
    const hasProfileKey = () => fieldFloral?.some((item) => item && item?.profiles);

    const handleSubmit = (e) => {
        e.preventDefault();
        const occasionFound = occasion_option_group?.find(
            (d) => d.occasion_title === occasion?.name,
        );
        const occasionValue = occasionFound?.occasion_url;
        const { name, relationshipName: selectedRelationShip = '' } = selectedProfileInfo;
        if (isValidation && (zip.length < 5 || (!relationship && hasProfileKey()))) {
            if (zip.length < 5 && onSubmitCheck === false) {
                setSubmitCheck(true);
            }
            if (!relationship && hasProfileKey()) {
                setErrorRelationship(true);
            }
        } else if (isValidation === false
           || (zip.length >= 5
            && validatedZip !== ''
            && validatedZip === zip
            && !errorRelationship)
        ) {
            const trackData = {
                ea: data?.a_b_testing?.submission_tacking_event_action || data?.tracking_event_action || 'Homepage',
                ec: data?.a_b_testing?.submission_tacking_event_category || data?.tracking_event_category || 'Gift Finder Gateway',
                el: data?.a_b_testing?.submission_tacking_event_label ? data?.a_b_testing?.submission_tacking_event_label.replace('{{Zip}}', zip).replace('{{Occasion}}', occasionFound?.occasion_title).replace('{{Profile}}', selectedRelationShip) : '',
            };
            if (ffIsGiftFinderTestEnabled) {
                ldClient.track('Gift Finder Button Clicked');
            }
            dispatch(trackEvent({
                eventCategory: trackData.ec,
                eventAction: trackData.ea,
                eventLabel: trackData?.el || `${zip}|${occasion.name}`,
                giftOccasion: occasion?.name,
                giftZipcode: zip,
            }));
            dispatch(setUserSubmittedProductFilterZipcode(zip.trimEnd()));
            const customTrackingSchema = {
                action: 'customer-input', relationship: selectedRelationShip, occasionCode: occasionFound?.occasion_title, page: { type: 'home' }, zipCode: zip, eventSubType: 'customer-input-find-gift-fast',
            };
            if (isAuthenticated && name) {
                const nameCollection = name.split(' ');
                customTrackingSchema.firstName = nameCollection?.[0];
                customTrackingSchema.lastName = nameCollection?.[1];
            }
            dispatch(emitCustomTrackingEvent(customTrackingSchema));
            if (occasionFound) {
                let url = occasionValue || '';
                let searchParam = '';
                if (occasionValue?.indexOf?.('?') >= 0) {
                    const [pathUrl, pathParam] = occasionValue?.split?.('?') || [];
                    url = pathUrl;
                    searchParam = pathParam;
                }
                const historyData = {
                    pathname: url || occasionValue,
                    state: {
                        giftOccasion: occasion?.name,
                        giftZipcode: zip,
                    },
                };
                if (searchParam) {
                    historyData.search = searchParam;
                }
                history.push(historyData);
            }
        }
    };

    const setSelectedOccasion = (occasionName) => {
        const currentOccasion = occasion_option_group.filter((occ) => occ.occasion_title === occasionName);
        dispatch(setProductFilterOccasion(occasionName, currentOccasion?.[0]?.occasion_code));
    };
    const ArrowDropDownIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
            <path d="M7.74742 8.65916C7.34962 9.10668 6.65039 9.10668 6.2526 8.65916L0.479437 2.16436C-0.0938094 1.51946 0.363996 0.499999 1.22685 0.499999L12.7732 0.5C13.636 0.5 14.0938 1.51946 13.5206 2.16436L7.74742 8.65916Z" fill="#262626" />
        </svg>
    );

    const renderFields = (block) => {
        const blockKey = Object.keys(block)?.[0];
        const blockObj = block[blockKey];
        switch (blockKey) {
            case 'zip_code':
                return (
                    <div className={classes.ctInputContainer}>
                        <ZipInputField
                            zipLabel={blockObj.field_label}
                            currentInputLength={zip.length}
                            handleChange={handleChange}
                            onSubmitCheck={onSubmitCheck}
                            zipcodeIsValid={zipcodeIsValid}
                            userSubmittedZip={userSubmittedZip}
                            value={zip}
                            giftFinderCodeTheoryView
                            giftFinderFieldStyle={classes}
                            inputBorderColor={colorObj?.field_borders?.color || '#C4C4C4'}
                            giftFinderBodyTextColor={colorObj?.field_labels?.color || '#595959'}
                        />
                    </div>
                );
            case 'profiles':
                if (isAuthenticated && addressData?.recipientRank?.recipientList?.length > 0 && !isPlaEnabled) {
                    return (
                        <div className={`${classes.ctInputContainer} ${classes.addressBookContainer}`}>
                            <LogInAddressBook
                                recipientLabel={blockObj.field_label}
                                classes={classes}
                                relationship={relationship}
                                setRelationship={setRelationship}
                                data={addressData}
                                errorRelationship={errorRelationship}
                                setErrorRelationship={setErrorRelationship}
                                stylingObj={{
                                    fieldLabels: colorObj?.field_labels?.color || '#595959',
                                    fieldBorders: `1px solid ${colorObj?.field_borders?.color || '#C4C4C4'}`,
                                }}
                                arrowDropDownIcon={ArrowDropDownIcon}
                            />
                        </div>
                    );
                }
                return (
                    !isPlaEnabled && (
                        <div className={`${classes.ctInputContainer} ${classes.addressBookContainer}`}>
                            <LogOutAddressBook
                                recipientLabel={blockObj.field_label}
                                classes={classes}
                                reference={blockObj?.profiles}
                                relationship={relationship}
                                setRelationship={setRelationship}
                                errorRelationship={errorRelationship}
                                setErrorRelationship={setErrorRelationship}
                                stylingObj={{
                                    fieldLabels: colorObj?.field_labels?.color || '#595959',
                                    fieldBorders: `1px solid ${colorObj?.field_borders?.color || '#C4C4C4'}`,
                                }}
                                arrowDropDownIcon={ArrowDropDownIcon}
                            />
                        </div>
                    )
                );
            case 'occasions':
                return (
                    <div className={`${classes.ctInputContainer}`}>
                        <OccasionsDropDownField
                            occasionLabel={blockObj.field_label}
                            stylingObj={{
                                fieldLabels: colorObj?.field_labels?.color || '#595959',
                                fieldBorders: `1px solid ${colorObj?.field_borders?.color || '#C4C4C4'}`,
                            }}
                            arrowDropDownIcon={ArrowDropDownIcon}
                            setSelectedOccasion={setSelectedOccasion}
                            classes={classes}
                            data={blockObj}
                            occasion={occasion}
                        />
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <div className={`${classes.container} ${classes.plaGiftFinderContainer}`}>
            <form className={classes.ctGfForm} onSubmit={handleSubmit}>
                <div className={classes.ctGfContainer}>
                    <div className={classes.giftFinderBgImage} style={giftFinderBgImage()}>
                        <div className={classes.gfInnerContainer} style={{ backgroundColor: giftFinderBgColor }}>
                            <div className={classes.iconAndHeaderContainer}>
                                <h3
                                    className={classes.ctHeader}
                                    style={{
                                        fontFamily: parseFont(data?.styling?.fonts?.heading_font) || 'PlayfairDisplayBold',
                                        color: colorObj.heading?.color || '#6f2b90',
                                    }}
                                >
                                    {ReactHtmlParser(sectionTitle?.replace?.('\\n', '<br/>'))}
                                </h3>
                            </div>
                            <div className={classes.ctFieldContainer}>
                                <div className={classes.ctFieldInnerContainer}>
                                    {fieldFloral.map((item) => (
                                        renderFields(item)
                                    ))}
                                    <div className={classes.ctButtonContainer}>
                                        <button
                                            id="fagf-btn"
                                            data-testid="fagf-btn"
                                            style={{
                                                backgroundColor: colorObj?.cta_background?.color || '#53237f',
                                                color: colorObj?.cta_font?.color || '#ffffff',
                                            }}
                                            className={classes.ctButton}
                                            type="submit"
                                        >
                                            {data.cta_text}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {ffIsGFSuggestionsEnabled &&  (
                <div
                    className={classes.giftFinderHorizontal}
                    style={{
                        backgroundColor: productData?.styling?.module_background_color?.color,
                    }}
                >
                    <GiftFinderProductContainer productData={productData} />
                </div>
            )}
        </div>
    );
};

FloralGFUniversal.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
    userSubmittedZip: string.isRequired,
    handleChange: func.isRequired,
    zipcodeIsValid: bool,
    occasion: shape({
        code: string,
        name: string,
    }).isRequired,
    zip: string.isRequired,
    isPlaEnabled: bool,
};
FloralGFUniversal.defaultProps = {
    zipcodeIsValid: null,
    isPlaEnabled: false,
};

export default (withStyles(styles)(FloralGFUniversal));
