/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import useGetAccessTokenSafely from './useGetAccessTokenSafely';
import { getProfileInfo } from '../../../state/ducks/App/ducks/Common/Common-Selectors';
import { getBrand } from '../../../state/ducks/App/ducks/Config/Config-Selectors';
import { GRAPHQL_ENV } from '..';
import findRecipientRank from '../queries/findRecipientRank';

const useAddressBookData = () => {
    const jwt = useGetAccessTokenSafely();
    const profile = useSelector(getProfileInfo);
    const brand = useSelector(getBrand);
    const queryVariables = {
        brand: brand.domain || 'harryanddavid',
        environment: GRAPHQL_ENV,
        emailId: profile.email,
        model: 'SCORE',
    };
    return  useQuery(findRecipientRank(), {
        variables: queryVariables,
        context: {
            api: 'personalizedContent',
            jwt,
        },
        skip: !jwt,
    });
};

export default useAddressBookData;
