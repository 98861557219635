/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const getBackgroundColor = (color, isLander = false) => {
    let backgroundColor = color?.color;
    const colorTransparency = color?.colorTransparency;

    if (color?.colorTheme === 'none') {
        backgroundColor = '';
        return backgroundColor;
    }
    if (colorTransparency && isLander) {
        backgroundColor = color?.color + color?.colorTransparency;
    }
    return backgroundColor;
};

export default getBackgroundColor;
