/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 *    returns an array of strings ( 10 dates )
 */

import { format, addDays } from 'date-fns';

export const AMPMToMilitary = (cutOffTime) => {
    // coverts standard US time into miliary time
    // eg. '1:30 PM' -> 1330
    // the elvis operators are to account for an undefined cutOffTime

    const timeAndAmOrPm = cutOffTime?.split(' '); // '2:30 PM' => ['2:30', 'PM'}
    const amOrPm = timeAndAmOrPm?.[1]; // 'PM'
    const time = timeAndAmOrPm?.[0]; // '2:30'
    const hoursMinutes = time?.split(':'); // '2:30' => ['2', '30']
    let hours = hoursMinutes?.[0]; // '2'
    const minutes = hoursMinutes?.[1]; // '30'
    if (amOrPm === 'PM' && Number(hours) !== 12) {
        hours = Number(hours); // '2' => 2
        hours += 12; // 2 => 14
    }
    const convertedTime = Number(`${hours}${minutes}`); // 14 '30' => 1430
    return convertedTime;
};

const getCutOffTime = (times, day) => {
    // change cutoff time depending on day of the week
    let cutOffTime = {
        cutoff_time: AMPMToMilitary(times?.[0]?.cutoff_time) || 1430,
        before_cutoff_days_added: times?.[0]?.before_cutoff_days_added || 0,
        after_cutoff_days_added: times?.[0]?.after_cutoff_days_added || 1,
    }; // 2:30PM default cutoff time for monday-friday
    if (day === 6) {
        // if saturday
        cutOffTime = {
            cutoff_time: AMPMToMilitary(times?.[1]?.cutoff_time) || 1300, // 1:00PM
            before_cutoff_days_added: times?.[1]?.before_cutoff_days_added || 0,
            after_cutoff_days_added: times?.[1]?.after_cutoff_days_added || 1,
        };
    } else if (day === 0) {
        // if sunday
        cutOffTime = {
            cutoff_time: AMPMToMilitary(times?.[2]?.cutoff_time) || 1130, // 11:30AM
            before_cutoff_days_added: times?.[2]?.before_cutoff_days_added || 0,
            after_cutoff_days_added: times?.[2]?.after_cutoff_days_added || 1,
        };
    }
    return cutOffTime;
};

const getCurrentTimeInFloat = (now) => {
    const currentHour = now.getHours();
    // STRING: current minute in military time add leading zero if minutes < 10
    // to prevent issue where if current time = 11:04 AM will show 114
    const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
    return Number.parseFloat(`${currentHour}${''}${minutes}`); // convert to int so we can compare numbers ( currenTime )
};

const generateExceptionDateObj = (exceptionDates) => {
    // takes array of exceptionDates and returns
    // obj with dates in array as keys and true as values
    const exceptionDateObj = {};
    if (exceptionDates?.length) {
        for (let i = 0; i <= exceptionDates.length - 1; i += 1) {
            exceptionDateObj[exceptionDates[i]] = true;
        }
    }
    return exceptionDateObj;
};

const determineCutOffTime = (now, cutOffTimes) => {
    const dayOfTheWeekInteger = now.getDay(); // get the day of the week for cutoff times
    const cutOffTime = getCutOffTime(cutOffTimes, dayOfTheWeekInteger);
    return cutOffTime;
};

const generateDatesArray = (now, isPastCutoff, numberOfDatesToShow, exceptionDateObj, cutOffTime) => {
    const datesArray = [];
    let loopDate;
    for (let i = 0; i < numberOfDatesToShow; i += 1) {
        //
        const dateAdder = i + (isPastCutoff ? cutOffTime.after_cutoff_days_added : cutOffTime.before_cutoff_days_added);
        // if the time is past cutoff, start incrementing with tomorrow
        // const dateAdder = i + (isPastCutoff ? 1 : 0);
        loopDate = addDays(now, dateAdder);
        // if the date is a CMS defined exception date
        // do not show as an option
        if (!exceptionDateObj[format(new Date(loopDate), 'yyyy-MM-dd')]) {
            const newDate = loopDate.toLocaleString('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
            });
            // add our formatted date to our array
            datesArray.push(newDate);
        }
    }
    return datesArray;
};

const getNextNDates = (numberOfDatesToShow = 10, cutOffTimes, exceptionDates) => {
    const now = new Date();
    // current hour in military time
    const currentTime = getCurrentTimeInFloat(now);
    // convert
    const cutOffTime = determineCutOffTime(now, cutOffTimes);
    // if currentTime is past cutoff begin date array with tomorrow
    const isPastCutoff = currentTime > cutOffTime.cutoff_time;
    // generate an object with CMS defined exception dates as keys and true as values
    const exceptionDateObj = generateExceptionDateObj(exceptionDates);

    const datesArray = generateDatesArray(now, isPastCutoff, numberOfDatesToShow, exceptionDateObj, cutOffTime);
    return datesArray;
};

export default getNextNDates;
