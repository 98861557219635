/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { uid } from 'react-uid';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

import {
    shape, array, string,
} from 'prop-types';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {  Input, FormHelperText } from '@material-ui/core';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { emitCustomTrackingEvent } from '../../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import GiftFinderLogOutProfile from './GiftFinderLogOutProfile';
import { parseFont } from '../../../../../../helpers/common/helper';
import GiftFinderLoginContainer from './GiftFinderLoginContainer';
import { getIsAuthenticatedStatus } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getProductFilterProfile } from '../../../../../../../state/ducks/App/App-Selectors';
import GiftFinderProductContainer from '../../../../GiftFinderProduct/GiftFinderProductContainer';

const useStyles = makeStyles((theme) => ({
    giftFinderGFB: ({ data }) => ({
        boxSizing: 'border-box',
        padding: '28px 0px',
        margin: '30px auto',
        maxWidth: '1400px',
        width: '100%',
        textAlign: 'center',
        height: 'auto',
        backgroundColor: data?.styling?.background_color?.color || '#ffffff',
        '& .MuiFormLabel-filled + .MuiInput-formControl': {
            borderColor: theme.palette.giftFinderSelectColor,
        },
    }),
    giftFinderGFBwithGrid: ({ data }) => ({
        boxSizing: 'border-box',
        padding: '28px 0px',
        margin: 'auto',
        maxWidth: '1400px',
        width: '100%',
        textAlign: 'center',
        height: 'auto',
        backgroundColor: data?.styling?.background_color?.color || '#ffffff',
        '& .MuiFormLabel-filled + .MuiInput-formControl': {
            borderColor: theme.palette.giftFinderSelectColor,
        },
    }),
    container: {
        width: '100%',
        maxWidth: '978px',
        margin: '0 auto',
        '& .MuiGrid-container': {
            '@media screen and (max-width: 601px)': {
                marginTop: 0,
                width: '100%',
                margin: 0,
            },
        },
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px',
        },
    },
    button: {
        fontFamily: 'Lato',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.04em',
        color: theme.palette.cms?.white || '#ffffff',
        fontWeight: 700,
        height: '45px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: 3,
        padding: 0,
        cursor: 'pointer',
        boxShadow: 'inherit',
        '&:hover': {
            color: theme.palette.white || '#ffffff',
            backgroundColor: theme.palette.cms?.black || '#2f2f2f',
        },
    },
    heading: {
        margin: '0 auto',
        paddingBottom: '40px',
        textAlign: 'center',
        '@media screen and (max-width: 601px)': {
            paddingBottom: '20px',
        },
    },

    headingCopy: ({ data }) => ({
        margin: 0,
        fontSize: '34px',
        lineHeight: '30px',
        fontWeight: '400',
        color: data?.styling?.font_color?.color || '#2f2f2f',
        fontFamily: parseFont(data?.styling?.font_family) || 'Playfair Display',
        '@media screen and (max-width: 601px)': {
            fontSize: '28px',
        },
        '@media screen and (min-width: 767px)': {
            '&:before': {
                right: 15,
                width: '28%',
                height: 1,
                content: '""',
                display: 'inline-block',
                position: 'relative',
                verticalAlign: 'middle',
                backgroundColor: data?.styling?.font_color?.color || '#2f2f2f',
            },
            '&:after': {
                left: 15,
                width: '28%',
                height: 1,
                content: '""',
                display: 'inline-block',
                position: 'relative',
                verticalAlign: 'middle',
                backgroundColor: data?.styling?.font_color?.color || '#2f2f2f',
            },
        },
    }),

    helperText: {
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        fontSize: '14px',
        overflowY: 'hidden',
        maxHeight: '0',
        top: '90%',
        transitionProperty: 'all',
        transitionDuration: '.5s',
        transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
        marginTop: '5px',
        '@media screen and (max-width: 601px)': {
            top: '100%',
            fontSize: '12px',
            margin: 0,
        },
        '& svg': {
            fontSize: '14px',
            borderRadius: '50%',
            color: theme.palette.giftFinderErrorColor,
            backgroundColor: theme.palette.white,
            marginRight: '5px',
        },
    },
    errorMsg: {
        '& $helperText': {
            color: theme.palette.giftFinderErrorColor,
            maxHeight: '25px',

        },
        '& $ctSelect': {
            '& select': {
                color: theme.palette.giftFinderErrorColor,
                boxShadow: `0px 0px 0px 1px ${theme.palette.giftFinderErrorColor}`,
            },
            '& svg': {
                fill: theme.palette.giftFinderErrorColor,
            },
        },
        '& .MuiInputLabel-formControl': {
            color: theme.palette.giftFinderErrorColor,
        },

    },
    elementHidden: {
        display: 'none',
    },
    mainContainer: {
        maxWidth: '700px',
        margin: '0 auto',
        '@media screen and (min-width: 600px)': {
            flexWrap: 'inherit',
            display: 'flex',

        },
    },
    ctInputContainer: {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
        '&:not(:last-child)': {
            marginRight: '18px',
        },
        '@media screen and (max-width: 600px)': {
            paddingBottom: '18px',
            marginRight: '0px',
        },
    },
    errorMessage: {
        '&:after': {
            bottom: -80,
            display: 'block',
        },
    },
    successMessage: {
        '&:after': {
            bottom: 0,
            display: 'block',
        },
    },
    ctSelect: {
        fontSize: '18px',
        marginTop: '20px !important',
        background: theme.palette.cms?.white || '#ffffff',
        border: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        '& input, & select': {
            height: '42px',
            padding: '0 12px 2px 12px',
            fontSize: '16px',
            color: '#000',
            borderRadius: '4px',
            boxShadow: '0px 0px 0px 1px #CECECE',
            outline: 0,
            '&:focus, &:active': {
                borderRadius: '4px',
                boxShadow: '0px 0px 0px 1px #CECECE',
                outline: 0,
                background: theme.palette.cms?.white || '#ffffff',
            },
        },
        '& svg': {
            position: 'absolute',
            right: '3px',
            fill: theme.palette.cms?.black || '#2f2f2f',
            fontSize: '2.4rem',
            top: 'calc(50% - 18px)',

        },
        '&.Mui-focused': {
            background: 'transparent',
        },
    },
    ctInputLabel: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: '#595959',
        fontSize: '14px',
        lineHeight: '16px',
        '@media screen and (max-width: 1025px)': {
            fontSize: '16px',
        },
    },
    ctButtonContainer: {
        flexGrow: '0',
        maxWidth: '100%',
        flexBasis: '100%',
        marginTop: '19px',
    },
    noDisplayDateFieldMarginBottom: {
        marginBottom: '6px',
    },

    customIcon: {
        position: 'absolute',
        right: '10px',
    },
    giftFinderHorizontal: {
        margin: '0 auto',
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: '24px',
        maxWidth: '1400px',
        width: '100%',
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px 30px',
            margin: '0px',
        },
    },
    fastLineGift: {
        marginBottom: '30px',
        '& $giftFinderGFB': {
            marginBottom: '0px',
        },
    },
}));

const FoodProfileGiftFinder = ({ data, ldGiftFinderVariant }) => {
    const isAggregatorFacetErrorResolver = useSelector(getFeatureFlag('is-facet-throwing-error-resolver-enabled'));
    let delimiter = '&';
    if (isAggregatorFacetErrorResolver) delimiter = '&facet=';
    const dataSelector = data?.food_finder_criteria;
    const productData = data?.product_recommendations || [];

    const classes = useStyles({ data });
    const dispatch = useDispatch();
    const history = useHistory();
    const isGiftSuggestionEnable = useSelector(getFeatureFlag('is-gift-finder-suggestions-enabled'));
    const ffHasHomepageTopMargin = useSelector(getFeatureFlag('has-homepage-top-margin'));
    const { whichGiftFinderSuggestion } = useFlags();

    const ffIsGFSuggestionsEnabled = (isGiftSuggestionEnable && whichGiftFinderSuggestion === 'variant' && data?.has_recommendations);

    const [state, setState] = useState({
        occasion: '',
        recipient: '',
        price: '',
        occasionKey: '',
        recipientkey: '',
        priceKey: '',
        disabled: 'disabled',
    });

    const [errorMessage, setErrorMessage] = useState({
        occasionError: false,
        recipientError: false,
        priceError: false,
    });
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const selectedProfileInfo = useSelector(getProductFilterProfile);

    const [values, setValues]  = useState([]);
    const [relationship, setRelationship] = useState({});
    const handleChange = (event, keyName) => {
        const {
            options, selectedIndex, value, name,
        } = event.target;
        const inputLabel = options[selectedIndex].innerHTML.toLowerCase();
        setState({
            ...state,
            [name]: value,
            [keyName]: inputLabel?.replace(/\s/g, '').split('$').join(''), // this regex replacing all the white space and dollar sign inside a string
        });
        setErrorMessage({
            ...errorMessage,
            [`${name}Error`]: !value,
        });
        try {
            const selectedValues = values;
            const selectedSplitValue = value.split(delimiter);
            const index = selectedValues.findIndex((check) => check === selectedSplitValue);
            if (index > -1) {
                selectedValues.splice(0, index);
            } else {
                selectedValues.push(...selectedSplitValue);
            }
            setValues(selectedValues);
        } catch (error) {
            return error;
        }
        return null;
    };

    const { tracking_link_action = '', tracking_link_category = '' } = data || {};

    const trackData = () => {
        const eventLabel = `${state.occasionKey}|${state.priceKey}`;
        dispatch(trackEvent({
            eventCategory: data?.a_b_testing?.submission_tacking_event_category  || data?.a_b_testing?.tracking_event_category || data?.tracking_event_category || tracking_link_category || 'Homepage',
            eventAction: data?.a_b_testing?.submission_tacking_event_action || data?.a_b_testing?.tracking_event_action || data?.tracking_event_action || tracking_link_action || 'Gift Finder Gateway',
            eventLabel,
        }));
    };

    const emitCustomClickstreamEvent = () => {
        const { name, relationshipName: selectedRelationShip = '' } = selectedProfileInfo;
        const occasionAfterSplit = state?.occasion?.split('|')[1].replaceAll('_', ' ');
        const customTrackingSchema = {
            action: 'customer-input', relationship: selectedRelationShip, occasionCode: occasionAfterSplit, page: { type: 'home' }, eventSubType: 'customer-input-find-gift-fast',
        };
        if (isAuthenticated && name) {
            const nameCollection = name.split(' ');
            customTrackingSchema.firstName = nameCollection?.[0];
            customTrackingSchema.lastName = nameCollection?.[1];
        }
        dispatch(emitCustomTrackingEvent(customTrackingSchema));
    };

    const validation = () => {
        let occasionError = false;  let priceError = false;
        let error = true;
        if (!state.occasion) {
            occasionError = true;
            error = false;
        }

        if (!state.price) {
            priceError = true;
            error = false;
        }
        setTimeout(() => { // hide the error message after two second
            setErrorMessage({
                occasionError: false,
                recipientError: false,
                priceError: false,
            });
        }, 2000);

        setErrorMessage({
            occasionError,
            priceError,
        });
        return error;
    };
    const handleOnClick = () => {
        if (validation()) {
            trackData();
            emitCustomClickstreamEvent();
            const recipientFacets = relationship?.label?.value[0]?.value ? `?facet=${relationship?.label?.value[0]?.value}${delimiter}` : '?facet=';
            const querystring = `${recipientFacets}${state.occasion}${delimiter}${state.price}`;
            const path = data?.collection_path ? data?.collection_path : '/';
            history.push({
                pathname: path,
                search: querystring,
                state: values,
            });
        }
    };

    const occasions = dataSelector?.occasion?.value;
    const prices = dataSelector?.price?.value;

    const errorShowing = (errorMsg) => (
        <FormHelperText
            aria-hidden="true"
            className={`${classes.helperText} `}
        >
            <CancelOutlinedIcon />{errorMsg}
        </FormHelperText>
    );
    const { occasionError, priceError } = errorMessage;
    const giftFinderGFBStyle = ffHasHomepageTopMargin ? `${classes.giftFinderGFBwithGrid} giftFinder` : `${classes.giftFinderGFB} giftFinder`;
    return (
        <div className={ffIsGFSuggestionsEnabled ? classes.fastLineGift : ''}>
            <div className={giftFinderGFBStyle}>
                <div className={classes.container}>
                    <div className={classes.heading}>
                        {data?.heading &&  <h2 aria-hidden="true" className={classes.headingCopy}>{data?.heading}</h2>}
                    </div>
                    {isAuthenticated ? <GiftFinderLoginContainer reference={data?.food_finder_criteria?.profiles} relationship={relationship} setRelationship={setRelationship} iconStyling={data?.styling} ldGiftFinderVariant={ldGiftFinderVariant} abTesting={data?.a_b_testing} />
                        : <GiftFinderLogOutProfile reference={data?.food_finder_criteria?.profiles} relationship={relationship} setRelationship={setRelationship} iconStyling={data?.styling} ldGiftFinderVariant={ldGiftFinderVariant} abTesting={data?.a_b_testing} /> }
                    <div className={classes.mainContainer}>
                        <div className={`${classes.ctInputContainer} ${occasionError ? classes.errorMsg : ''}`}>
                            <FormControl fullWidth>
                                <InputLabel
                                    shrink
                                    htmlFor="occasion-native-label-placeholder"
                                    className={classes.ctInputLabel}
                                >
                                    Occasion
                                </InputLabel>
                                <NativeSelect
                                    defaultValue=""
                                    className={classes.ctSelect}
                                    value={state.occasion}
                                    disableUnderline
                                    onChange={(e) => handleChange(e, 'occasionKey')}
                                    error
                                    input={(
                                        <Input
                                            name="occasion"
                                            id="occasion_select"
                                            data-test="hp-occasion-native-label-placeholder"
                                        />
                                    )}
                                >
                                    <option className={state.occasion && classes.elementHidden} disabled aria-hidden="true" value="">What&rsquo;s the Occasion?</option>
                                    {
                                        occasions?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                    }
                                </NativeSelect>
                                {errorShowing('Please choose an occasion')}
                            </FormControl>
                        </div>
                        <div className={`${classes.ctInputContainer} ${priceError ? classes.errorMsg : ''}`}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="occasion-native-label-placeholder"className={classes.ctInputLabel}>Price Range</InputLabel>
                                <NativeSelect
                                    defaultValue=""
                                    className={classes.ctSelect}
                                    value={state.price}
                                    disableUnderline
                                    error
                                    onChange={(e) => handleChange(e, 'priceKey')}
                                    input={(
                                        <Input
                                            name="price"
                                            id="price_select"
                                            data-test="hp-priceKey-native-label-placeholder"
                                        />
                                    )}
                                >
                                    <option className={state.price && classes.elementHidden} disabled aria-hidden="true" value="">What&rsquo;s Your Budget?</option>
                                    {
                                        prices?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                    }
                                </NativeSelect>
                                { errorShowing('Please choose a budget')}
                            </FormControl>
                        </div>
                        <div className={classes.ctButtonContainer}>
                            <Button onClick={() => handleOnClick()} className={classes.button} variant="contained" color="primary">Search</Button>
                        </div>
                    </div>
                </div>
            </div>
            {ffIsGFSuggestionsEnabled && (
                <div
                    className={classes.giftFinderHorizontal}
                    style={{
                        backgroundColor: productData?.styling?.module_background_color?.color,
                    }}
                >
                    <GiftFinderProductContainer productData={productData} />
                </div>
            )}
        </div>
    );
};

FoodProfileGiftFinder.propTypes = {
    data: shape({
        food_finder_criteria: shape({
            heading: string,
            occasion: shape({
                value: array.isRequired,
            }),
        }),
        price: shape({
            value: array.isRequired,
        }),
        profiles: array,
        collection_path: string,
        presentation_type: string.isRequired,
        styling: array,
    }).isRequired,
    ldGiftFinderVariant: string.isRequired,
};

export default FoodProfileGiftFinder;
