/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
    array, bool, func,  object,
} from 'prop-types';

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '767px',
        width: '100%',
        minHeight: '400px',
        backgroundColor: '#F3F2F2',
        '& .MuiDialogContent-root': {
            padding: '16px',
        },
    },
    dialogHeader: {
        boxShadow: '0px 3px 3px rgb(47 47 47 / 15%)',
        padding: '10px 20px',
        '& h2': {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    headerContent: {
        fontSize: '16px',
        lineHeight: '20px',
        fontFamily: 'LatoBold',
        letterSpacing: '0.01em',
        margin: 'auto 0',
        '@media screen and (max-width: 600px)': {
            fontSize: 16,
        },
    },
    closeIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 'auto 0',
        float: 'right',
    },
    mainContainer: {
        padding: '18px 24px',
        marginBottom: '4px',
        backgroundColor: 'white',
        display: 'flex',
        gap: '36px',
        cursor: 'pointer',
        '@media screen and (max-width: 600px)': {
            padding: '12px 16px',
        },
    },
    name: {
        textTransform: 'capitalize',
        fontSize: '16px',
        lineHeight: '20px',
        fontFamily: 'LatoBold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '28%',
    },
    relationship: {
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Lato',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '15%',
        '@media screen and (max-width: 600px)': {
            fontSize: '14px',
            lineHeight: '18px',
        },
    },
    address: {
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Lato',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '45%',
        '@media screen and (max-width: 600px)': {
            fontSize: '14px',
            lineHeight: '18px',
        },
    },
    selectedContainer: {
        boxShadow: `0px 0px 0px 2px ${theme.palette.colorPrimary}`,
    },
});

const ProfileModal = ({
    products, isOpen, onClose, classes, selectedRelationship, relationship, loggedOut,
}) => {
    const loginProfile = () => (
        products.map((profile) => (
            <>
                <div  className={`${classes.mainContainer} ${relationship?.recipFirstName === profile?.recipFirstName ? classes.selectedContainer : '' }`} onClick={() => { selectedRelationship(profile); onClose(); }} role="presentation" data-testid={profile?.recipFirstName}>
                    <span className={classes.name}>{profile?.recipFirstName?.toLowerCase()} {profile?.recipLastName?.toLowerCase()}</span>
                    {profile?.relationship && <span className={`${classes.relationship}  ${classes.profile}`}>{profile?.relationship?.toLowerCase()}</span>}
                    {profile?.address && <span className={`${classes.address}  ${classes.profile}`}>{profile?.address}</span>}
                </div>
            </>
        ))
    );
    const loggedOutProfile = () => (
        products.map((product) => (
            <>
                <div  className={`${classes.mainContainer} ${relationship?.label?.value?.[0]?.key === product?.label?.value?.[0]?.key ? classes.selectedContainer : '' }`} onClick={() => { selectedRelationship(product); onClose(); }} role="presentation">
                    <span className={classes.relationship}>{product?.instance_label?.toLowerCase()}</span>
                </div>
            </>
        ))
    );

    return (
        <Dialog
            classes={{
                paper: classes.dialogPaper,
            }}
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle className={classes.dialogHeader}>
                <span className={classes.headerContent}>SELECT A RECIPIENT</span>
                <span role="presentation" onClick={() => { onClose(); }}>
                    <CloseIcon className={classes.closeIcon}  />
                </span>
            </DialogTitle>
            <DialogContent>
                {loggedOut ? loggedOutProfile() : loginProfile()}
            </DialogContent>
        </Dialog>
    );
};

ProfileModal.propTypes = {
    products: array,
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    selectedRelationship: func.isRequired,
    classes: object.isRequired,
    relationship: object,
    loggedOut: bool,
};
ProfileModal.defaultProps = {
    loggedOut: false,
    relationship: {},
    products: [],
};
export default  (withStyles(styles)(ProfileModal));
