/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    array, object, string, func, bool,
} from 'prop-types';
// import { withStyles } from '@material-ui/styles';
import {
    FormControl, FormHelperText, Input, InputLabel, NativeSelect,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import useUIDQueryConditionally from '../../../../../../helpers/hooks/useUIDQueryConditionally';
import { setProductFilterProfile } from '../../../../../../../state/ducks/App/App-Actions';

// const styles = (theme) => ({});

const LogOutAddressBook = ({
    reference, classes, stylingObj, arrowDropDownIcon, setRelationship,
    relationship, errorRelationship, setErrorRelationship, recipientLabel,
}) => {
    const { data } = useUIDQueryConditionally({
        data: {
            reference,
        },
        newDataSelector: (dta) => dta?.findContentByUID?.content?.entry || data,
    });
    const profileData = data?.profile || [];
    const dispatch = useDispatch();
    const selectedRelationship = (option) => {
        if (option) {
            const filterObject = profileData.filter((profile) => profile.label.value.some((label) => label.value === option),
            );
            setRelationship(option);
            setErrorRelationship(false);
            dispatch(setProductFilterProfile('', filterObject[0]?.relationship_code, filterObject[0]?.instance_label));
        }
    };

    return (
        <FormControl fullWidth className={errorRelationship ? classes.error : ''}>
            <InputLabel
                shrink
                htmlFor="recipient-native-label-placeholder"
                className={classes.ctInputLabel}
                style={{ color: stylingObj?.fieldLabels }}
            >
                {recipientLabel}
            </InputLabel>
            <NativeSelect
                disableUnderline
                value={relationship}
                onChange={(e) => {
                    selectedRelationship(e.target.value);
                }}
                className={`${classes.ctSelect} ${relationship ? classes.selectRoot : ''}`}
                IconComponent={() => (arrowDropDownIcon)}
                style={{ border: stylingObj?.fieldBorders }}
                input={(
                    <Input
                        name="Recipient"
                        id="recipient-native-label-placeholder"
                        data-test="hp-recipient-native-label-placeholder"
                    />
                )}
            >
                {Object.keys(relationship).length === 0 && (
                    <option aria-hidden="true" value="">
                        Select a recipient
                    </option>
                )}
                {profileData?.map((d) => (
                    <option
                        value={d.label?.value[0]?.value}
                        key={d.label?.value[0]?.key}
                    >
                        {d.label?.value[0]?.key}
                    </option>
                ))}
            </NativeSelect>
            {errorRelationship  && (
                <FormHelperText
                    aria-hidden="true"
                    className={`${classes.helperText} `}
                >
                    Invalid  Select a recipient
                </FormHelperText>
            )}

        </FormControl>
    );
};

LogOutAddressBook.propTypes = {
    reference: array,
    classes: object.isRequired,
    arrowDropDownIcon: string,
    stylingObj: object,
    relationship: object,
    setRelationship: func,
    errorRelationship: bool,
    setErrorRelationship: func,
    recipientLabel: string.isRequired,
};
LogOutAddressBook.defaultProps = {
    reference: [],
    relationship: '',
    setRelationship: () => { },
    errorRelationship: '',
    setErrorRelationship: () => { },
    stylingObj: {},
    arrowDropDownIcon: '',
};

export default LogOutAddressBook;
