/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    array, func, number, object, string,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import AddIcon from '@material-ui/icons/Add';
import useUIDQueryConditionally from '../../../../../../helpers/hooks/useUIDQueryConditionally';
import ProfileModal from './ProfileModal';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

import { setProductFilterProfile } from '../../../../../../../state/ducks/App/App-Actions';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    image: {
        width: '100%',
        height: '100%',
        aspectRatio: '115/111',
        borderRadius: '50%',
        cursor: 'pointer',
        padding: '4px',
        boxShadow: 'inset 0px 0px 0px 1px #9D9D9D',
        '@media screen and (max-width: 1025px)': {
            padding: '3px',
        },
    },
    selectedImage: {
        boxShadow: `inset 0px 0px 0px 3px ${theme.palette.colorPrimary}`,
        '@media screen and (max-width: 600px)': {
            boxShadow: `inset  0px 0px 0px 2px ${theme.palette.colorPrimary}`,
        },
    },
    name: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        width: '97%',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        textTransform: 'capitalize',
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: 'Lato',
        paddingBottom: '8px',
    },
    relationship: {
        lineHeight: '18px',
    },
    nonSelectedAddIcon: {
        boxShadow: 'inset 0px 0px 0px 1px #9D9D9D',
        borderRadius: '50%',
        padding: '4px',
        marginBottom: '8px',
    },
    addIcon: {
        boxShadow: 'inset 0px 0px 0px 1px #9D9D9D',
        borderRadius: '50%',
        height: '79px',
        width: '79px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media screen and (max-width: 1025px)': {
            padding: '3px',
            height: '65px',
            width: '65px',
        },
    },
    imageContainer: {
        height: '87px',
        width: '87px',
        marginBottom: '8px',
        position: 'relative',
        '@media screen and (max-width: 1025px)': {
            height: '71px',
            width: '71px',
        },
    },
    innerContainer: {
        width: '87px',
        marginRight: '11px',
        '@media screen and (min-width: 767px)': {
            '&:not(:last-child)': {
                marginRight: '12px',
            },
        },
        '@media screen and (max-width: 1025px)': {
            width: '71px',
        },
    },
    mainContainer: {
        margin: '0 auto 16px',
        width: '97%',
        overflow: 'auto',
        '@media screen and (max-width: 767px)': {
            margin: '0 auto',
            width: '96%',
            maxWidth: '1335px',
            padding: '2px',
            flexWrap: 'nowrap',
            display: 'flex',
            overflowX: 'auto',
            marginBottom: '16px',
            '&::-webkit-scrollbar-thumb': {
                display: 'none',
            },
            '&::-webkit-scrollbar': {
                height: '3px',
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: '10px',
                backgroundColor: 'transparent',
            },
            scrollbarColor: 'rgba(213, 213, 213, 1) transparent',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        '@media screen and (max-width: 767px)': {
            display: 'grid',
            gridTemplateColumns: `repeat(${10}, 1fr)`,
        },
    },
    loginContainer: {
        width: 'auto',
        '& $container': {
            display: 'flex',
        },
    },
    smallImageContainer: {
        '& $innerContainer': {
            width: '66px',
            '&:not(:last-child)': {
                marginRight: '23px',
            },
        },
        '& $imageContainer': {
            height: '66px',
            width: '66px',
            position: 'relative',
        },
        '& $image': {
            padding: '3px',
        },
        '& $selectedImage': {
            boxShadow: `inset  0px 0px 0px 2px ${theme.palette.colorPrimary}`,
        },
        '& $addIcon': {
            width: '60px',
            height: '60px',
        },
        '& $nonSelectedAddIcon': {
            padding: '3px',
        },
    },
    firstLetterOverlap: {
        position: 'absolute',
        top: 0,
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Playfair Display',
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
});

const GiftFinderLogOutProfile = ({
    reference, classes, setRelationship, relationship, showLoggedoutProfile, iconStyling, abTesting, ldGiftFinderVariant,
}) => {
    const { data } = useUIDQueryConditionally({
        data: {
            reference,
        },
        newDataSelector: (dta) => dta?.findContentByUID?.content?.entry || data,
    });
    const profileData = data?.profile || [];
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const dispatch = useDispatch();
    const ffIsGiftFinderTestEnabled = useSelector(getFeatureFlag('is-gift-finder-test-enabled'));
    let ldClient;
    if (ffIsGiftFinderTestEnabled && typeof window !== 'undefined') {
        ldClient = useLDClient();
    }
    const selectedRelationship = (option) => {
        if (relationship?.label?.value?.[0]?.key === option?.label?.value?.[0]?.key) {
            setRelationship('');
            dispatch(setProductFilterProfile());
        } else {
            setRelationship(option);
            dispatch(setProductFilterProfile('', option?.relationship_code, option?.instance_label));
        }
    };
    const openModal = () => {
        if (ffIsGiftFinderTestEnabled) {
            ldClient.track('Recipient Tiles GF');
        }
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const showProducts = showLoggedoutProfile || 9;
    const displayedProducts = profileData?.slice(showProducts, profileData?.length);
    const iconSize = iconStyling?.icon_size || 'lg';
    return (
        <div className={showLoggedoutProfile ? `${classes.loginContainer} ${classes.mainContainer}` : classes.mainContainer} key={profileData}>
            <div className={`${classes.container}  ${iconSize === 'sm' ? classes.smallImageContainer : ''}`}>
                {profileData?.slice(0, showProducts).map((options) => (
                    <div
                        role="presentation"
                        onClick={() => {
                            if (ffIsGiftFinderTestEnabled) {
                                ldClient.track('Recipient Tiles GF');
                            }
                            dispatch(trackEvent({
                                eventCategory: data?.ga_tracking_constants?.tracking_event_category || 'Homepage',
                                eventAction: data?.ga_tracking_constants?.tracking_event_action || 'Gift Finder - Tile Click',
                                eventLabel: options?.linking?.tracking_event_label || 'Recipient Logged Out',
                            }));
                            selectedRelationship(options);
                        }}
                        key={options?.image.title}
                        className={classes.innerContainer}
                    >
                        <div className={classes.imageContainer}>
                            <ResponsiveImage
                                className={`${classes.image} ${relationship?.label?.value?.[0]?.key === options?.label?.value?.[0]?.key ? classes.selectedImage : classes.nonSelectedImage}`}
                                path={options?.image.url}
                                alt={options?.image.title || 'relationshipImage'}
                                params={{}}
                            />
                            {ldGiftFinderVariant === 'variant a' && abTesting?.variant?.toLowerCase() === 'variant a'  && <span className={classes.firstLetterOverlap}>{options?.instance_label?.split('')?.[0]}</span>}
                        </div>
                        <span className={classes.name}>{options?.instance_label}</span>
                    </div>
                ))}
                {displayedProducts?.length > 0 && !showLoggedoutProfile && (
                    <div className={classes.innerContainer}>
                        <Button onClick={openModal} className={classes.nonSelectedAddIcon}>
                            <span className={classes.addIcon}>
                                <AddIcon />
                            </span>
                        </Button>
                        <div className={classes.nameContainer}>
                            <span className={classes.name}>View More</span>
                        </div>
                    </div>
                )}
            </div>
            <ProfileModal
                products={displayedProducts}
                isOpen={modalIsOpen}
                onClose={closeModal}
                selectedRelationship={selectedRelationship}
                relationship={relationship}
                loggedOut
            />
        </div>
    );
};

GiftFinderLogOutProfile.propTypes = {
    reference: array,
    classes: object.isRequired,
    relationship: object,
    setRelationship: func,
    showLoggedoutProfile: number,
    iconStyling: object,
    abTesting: object,
    ldGiftFinderVariant: string,
};
GiftFinderLogOutProfile.defaultProps = {
    reference: [],
    relationship: {},
    setRelationship: () => {},
    showLoggedoutProfile: '',
    iconStyling: {},
    abTesting: {},
    ldGiftFinderVariant: '',
};

export default withStyles(styles)(GiftFinderLogOutProfile);
