/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    array, func, object, string,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import AddIcon from '@material-ui/icons/Add';
import ProfileModal from './ProfileModal';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import GiftFinderLogOutProfile from './GiftFinderLogOutProfile';

import { setProductFilterProfile } from '../../../../../../../state/ducks/App/App-Actions';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    image: {
        width: '100%',
        height: '100%',
        aspectRatio: '115/111',
        borderRadius: '50%',
        cursor: 'pointer',
        padding: '4px',
        boxShadow: 'inset 0px 0px 0px 1px #9D9D9D',
        '@media screen and (max-width: 1025px)': {
            padding: '3px',
        },
    },
    name: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        width: '97%',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        textTransform: 'capitalize',
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: 'Lato',
        paddingBottom: '8px',
    },
    relationship: {
        lineHeight: '18px',
    },
    nonSelectedAddIcon: {
        boxShadow: 'inset 0px 0px 0px 1px #9D9D9D',
        borderRadius: '50%',
        padding: '4px',
        marginBottom: '8px',
    },
    addIcon: {
        boxShadow: 'inset 0px 0px 0px 1px #9D9D9D',
        borderRadius: '50%',
        height: '79px',
        width: '79px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media screen and (max-width: 1025px)': {
            padding: '3px',
            height: '65px',
            width: '65px',
        },
    },
    imageContainer: {
        height: '87px',
        width: '87px',
        marginBottom: '8px',
        position: 'relative',
        '@media screen and (max-width: 1025px)': {
            height: '71px',
            width: '71px',
        },
    },
    innerContainer: {
        width: '87px',
        marginRight: '11px',
        '@media screen and (min-width: 767px)': {
            '&:not(:last-child)': {
                marginRight: '12px',
            },
        },
        '@media screen and (max-width: 1025px)': {
            width: '71px',
        },
    },
    mainContainer: {
        margin: '0 auto 16px',
        width: '97%',
        overflow: 'auto',
        '@media screen and (max-width: 767px)': {
            margin: '0 auto',
            width: '96%',
            maxWidth: '1335px',
            padding: '2px',
            flexWrap: 'nowrap',
            display: 'flex',
            overflowX: 'auto',
            marginBottom: '16px',
            '&::-webkit-scrollbar-thumb': {
                display: 'none',
            },
            '&::-webkit-scrollbar': {
                height: '3px',
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: '10px',
                backgroundColor: 'transparent',
            },
            scrollbarColor: 'rgba(213, 213, 213, 1) transparent',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        '@media screen and (max-width: 767px)': {
            display: 'grid',
            gridTemplateColumns: `repeat(${10}, 1fr)`,
        },
    },

    selectedWrapper: {
        '& $image , $nonSelectedAddIcon': {
            boxShadow: `inset 0px 0px 0px 3px ${theme.palette.colorPrimary}`,
            '@media screen and (max-width: 600px)': {
                boxShadow: `inset  0px 0px 0px 2px ${theme.palette.colorPrimary}`,
            },
        },
        '& $name': {
            color: theme.palette.colorPrimary,
            fontWeight: '600',
        },
    },
    smallImageContainer: {
        '& $innerContainer': {
            width: '66px',
            '&:not(:last-child)': {
                marginRight: '23px',
            },

        },
        '& $imageContainer': {
            height: '66px',
            width: '66px',
            position: 'relative',
        },
        '& $image': {
            padding: '3px',
        },
        '& $addIcon': {
            width: '60px',
            height: '60px',
        },
        '& $nonSelectedAddIcon': {
            padding: '3px',
        },
        '& $selectedWrapper': {
            '& $image , $nonSelectedAddIcon': {
                boxShadow: `inset 0px 0px 0px 2px ${theme.palette.colorPrimary}`,
            },
        },
    },
    firstLetterOverlap: {
        position: 'absolute',
        top: 0,
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Playfair Display',
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
});

const GiftFinderLoginProfile = ({
    classes, loginContent, reference, relationship, setRelationship, iconStyling, abTesting, ldGiftFinderVariant,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const dispatch = useDispatch();
    const ffIsGiftFinderTestEnabled = useSelector(getFeatureFlag('is-gift-finder-test-enabled'));
    let ldClient;
    if (ffIsGiftFinderTestEnabled && typeof window !== 'undefined') {
        ldClient = useLDClient();
    }
    const selectedRelationship = (option) => {
        const name = `${option?.recipFirstName} ${option?.recipLastName}`;
        if (relationship?.recipFirstName === option?.recipFirstName) {
            setRelationship('');
            dispatch(setProductFilterProfile());
        } else {
            setRelationship(option);
            dispatch(setProductFilterProfile(name, option?.relationship, option?.relationship));
        }
    };
    const openModal = () => {
        if (ffIsGiftFinderTestEnabled) {
            ldClient.track('Recipient Tiles GF');
        }
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const iconSize = iconStyling?.icon_size || 'lg';
    const showProfile = loginContent?.recipientList?.slice(0, 9);
    const showLoggedoutProfile = 9 - showProfile?.length;
    const displayedProducts = loginContent?.recipientList?.slice(9, loginContent?.recipientList?.length);
    const imageUrl = 'https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blt4d65998f3e7e34c9/64ed8ff8343ec75d111dd18e/gift_finder_gateway_profile_placeholder_130x130.jpg';
    return (
        <div className={classes.mainContainer} key={loginContent}>
            <div className={`${classes.container}  ${iconSize === 'sm' ? classes.smallImageContainer : ''}`}>
                {showProfile?.length && showProfile?.map((product, index) => (
                    <div
                        role="presentation"
                        onClick={() => {
                            if (ffIsGiftFinderTestEnabled) {
                                ldClient.track('Recipient Tiles GF');
                            }
                            dispatch(trackEvent({
                                eventCategory: loginContent?.content?.ga_tracking_constants?.tracking_event_category || 'Homepage',
                                eventAction: loginContent?.content?.ga_tracking_constants?.tracking_event_action || 'Gift Finder - Tile Click',
                                eventLabel: loginContent?.content?.profile[index]?.linking?.tracking_event_label || 'Recipient Logged In',
                            }));
                            selectedRelationship(product);
                        }}
                        key={product?.recipFirstName}
                        className={`${classes.innerContainer} ${(relationship?.recipFirstName === product?.recipFirstName && relationship?.recipLastName === product?.recipLastName)  ? classes.selectedWrapper : ''}`}
                    >
                        <div className={classes.imageContainer}>
                            <ResponsiveImage
                                path={loginContent?.content?.profile[index]?.image?.url ? loginContent?.content?.profile[index]?.image?.url : imageUrl}
                                alt={product?.recipFirstName || 'relationshipImage'}
                                className={`${classes.image}`}
                                params={{}}
                            />
                            {ldGiftFinderVariant === 'variant a' && abTesting?.variant?.toLowerCase() === 'variant a' &&   <span className={classes.firstLetterOverlap}>{product?.recipFirstName?.split('')?.[0]}{product?.recipLastName?.split('')?.[0]}</span>}
                        </div>
                        <span className={classes.name}>{product?.recipFirstName?.toLowerCase()}{' '} {product?.recipLastName?.toLowerCase()}</span>
                        <span className={`${classes.relationship} ${classes.name}`}>{product?.relationship?.toLowerCase()} </span>
                    </div>
                ))}
                {showProfile?.length < 9 &&  <GiftFinderLogOutProfile  reference={reference} relationship={relationship} setRelationship={setRelationship} showLoggedoutProfile={showLoggedoutProfile} />}
                {displayedProducts?.length > 0 && (
                    <div className={`${classes.innerContainer}  ${displayedProducts.some((check) => check?.recipFirstName === relationship?.recipFirstName && relationship?.recipLastName === check?.recipLastName) ? classes.selectedWrapper : ''}`}>
                        <Button onClick={openModal} className={`${classes.nonSelectedAddIcon}`}>
                            <span className={classes.addIcon}>
                                <AddIcon />
                            </span>
                        </Button>
                        <div className={classes.nameContainer}>
                            <span className={classes.name}>More</span>
                        </div>
                    </div>
                )}
            </div>
            <ProfileModal
                products={displayedProducts}
                isOpen={modalIsOpen}
                onClose={closeModal}
                selectedRelationship={selectedRelationship}
                relationship={relationship}
            />
        </div>
    );
};

GiftFinderLoginProfile.propTypes = {
    classes: object.isRequired,
    loginContent: object,
    reference: array,
    relationship: object,
    setRelationship: func,
    iconStyling: object,
    abTesting: object,
    ldGiftFinderVariant: string,
};
GiftFinderLoginProfile.defaultProps = {
    loginContent: {},
    reference: [],
    relationship: {},
    setRelationship: () => {},
    iconStyling: {},
    abTesting: {},
    ldGiftFinderVariant: '',
};

export default withStyles(styles)(GiftFinderLoginProfile);
