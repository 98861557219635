/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControl, Input, InputLabel, NativeSelect, FormHelperText,
} from '@material-ui/core';

import {
    func, object, string, bool,
} from 'prop-types';

import { setProductFilterProfile } from '../../../../../../../state/ducks/App/App-Actions';

const LogInAddressBook = ({
    classes, setRelationship,
    relationship, data, stylingObj,
    arrowDropDownIcon,
    errorRelationship,
    setErrorRelationship,
    recipientLabel,
}) => {
    const dispatch = useDispatch();
    const selectedRelationship = (option) => {
        if (option) {
            const filterObject = data?.recipientRank?.recipientList?.filter((item) => `${item?.recipFirstName} ${item.recipLastName}` === option);
            setRelationship(option);
            setErrorRelationship(false);
            dispatch(setProductFilterProfile(option, filterObject?.[0]?.relationship, filterObject?.[0]?.relationship));
        }
    };

    return (
        <FormControl fullWidth className={errorRelationship ? classes.error : ''}>
            <InputLabel
                shrink
                htmlFor="recipient-native-label-placeholder"
                className={classes.ctInputLabel}
                style={{ color: stylingObj?.fieldLabels }}
            >
                {recipientLabel}
            </InputLabel>
            <NativeSelect
                disableUnderline
                value={relationship}
                onChange={(e) => {
                    selectedRelationship(e.target.value);
                }}
                className={`${classes.ctSelect} ${relationship ? classes.selectRoot : ''}`}
                IconComponent={() => (arrowDropDownIcon)}
                style={{ border: stylingObj?.fieldBorders }}
                input={(
                    <Input
                        name="recipient"
                        id="recipient-native-label-placeholder"
                        data-test="hp-recipient-native-label-placeholder"
                    />
                )}
            >
                {Object.keys(relationship).length === 0 && (
                    <option aria-hidden="true" value="">
                        Select from address book
                    </option>
                )}
                {data?.recipientRank?.recipientList?.map((d) => (
                    <option
                        value={`${d.recipFirstName} ${d.recipLastName}`}
                        key={d.recipFirstName}
                    >
                        {`${d.recipFirstName?.toLowerCase()} ${d.recipLastName?.toLowerCase()}`}
                    </option>
                ))}
            </NativeSelect>
            {errorRelationship && (
                <FormHelperText
                    aria-hidden="true"
                    className={`${classes.helperText} `}
                >
                    Invalid Select from address book
                </FormHelperText>
            )}
        </FormControl>
    );
};
LogInAddressBook.propTypes = {
    classes: object.isRequired,
    relationship: object,
    setRelationship: func.isRequired,
    data: object,
    arrowDropDownIcon: string,
    stylingObj: object,
    errorRelationship: bool,
    setErrorRelationship: func,
    recipientLabel: string.isRequired,
};
LogInAddressBook.defaultProps = {
    relationship: '',
    data: {},
    stylingObj: {},
    arrowDropDownIcon: '',
    errorRelationship: false,
    setErrorRelationship: () => { },
};
export default LogInAddressBook;
