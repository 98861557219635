/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    bool,
    func,
    object,
    shape,
    string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import FloralProfileGiftFinder from './FloralProfileGiftFinder';
import FoodProfileGiftFinder from './FoodProfileGiftFinder';
import { getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const GiftFinderProfileContainer =  ({
    blockData, userSubmittedZip, zip, handleChange, onSubmitCheck, zipcodeIsValid, occasion, ldGiftFinderVariant,
}) => {
    const presentationFamily = useSelector(getPresentationFamily);
    return (
        <>
            {blockData?.presentation_type === 'flowers' && presentationFamily === 'flower'
                ? (
                    <FloralProfileGiftFinder
                        data={blockData}
                        userSubmittedZip={userSubmittedZip}
                        zip={zip}
                        handleChange={handleChange}
                        onSubmitCheck={onSubmitCheck}
                        zipcodeIsValid={zipcodeIsValid}
                        occasion={occasion}
                        ldGiftFinderVariant={ldGiftFinderVariant}
                    />
                )
                : (
                    <FoodProfileGiftFinder
                        data={blockData}
                        ldGiftFinderVariant={ldGiftFinderVariant}
                    />
                )}

        </>
    );
};

GiftFinderProfileContainer.propTypes = {
    blockData: object.isRequired,
    userSubmittedZip: string.isRequired,
    handleChange: func.isRequired,
    onSubmitCheck: bool.isRequired,
    zipcodeIsValid: bool,
    occasion: shape({
        code: string,
        name: string,
    }).isRequired,
    zip: string.isRequired,
    ldGiftFinderVariant: string,
};
GiftFinderProfileContainer.defaultProps = {
    zipcodeIsValid: null,
    ldGiftFinderVariant: '',
};

export default GiftFinderProfileContainer;
