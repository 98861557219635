/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
TODO; consolidate helpers, either by component or source or function.
This is a copy of:
src/app/components/GraphqlComponents/GraphqlHeader/NavigationMenu_V2/helpers/parseFontFamily.js

This regex is reused within a component already, see:
src/app/components/ContentTypeComponents/BannerV2/BannerV2.js
*/

// match css value between "font-family:" and ";"
const parseFontFamilyExtension = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;
export default parseFontFamilyExtension;
